.chart_section_2 {
  grid-area: 3 / 3 / 3 / 5;
}

.chart_section_2 .value {
  font-size: 1.5em;
  color: #000;
  font-weight: 500;
}

.chart_section_2 .title {
  font-size: 0.85em;
  color: #777;
  margin-top: -6px;
}