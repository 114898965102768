.chart_section_1 {
  grid-area: 2 / 3 / 2 / 5;
}

.chart_section_1 .value {
  font-size: 1.5em;
  color: #000;
  font-weight: 500;
}

.chart_section_1 .title {
  font-size: 0.85em;
  color: #777;
  margin-top: -6px;
}