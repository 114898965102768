.notFoundResult {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 80px 0 20px;
}

.notFoundResult img {
    width: 100%;
    height: auto;
    margin: 0 auto;
}