.live-map-container{
    position: relative;
    min-height: 100%;
    width:100%;
    margin-top:-16px;
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 0 0 5px 1px rgba(0,0,0,0.3);
}

.live-map-container > div{
    position: absolute;
    top:0;
    height: 100%;
    transition: all ease 0.2s;
}

.live-map-container .riders-handler{
    position: absolute;
    top: 8px;
    left: 8px;
    width: 100px;
    height: 40px;
    background-color: #fff;
    border-radius: 2px;
    z-index: 2;
    color:#555;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}



.live-map-container .l-m-left-panel{
    z-index:3;
    left:-300px;
    width:300px;
    overflow-y: hidden;
    height: 100%;
    background-color: #fff;
}

.live-map-container .l-m-left-panel .close-riders-handler{
    position: absolute;
    top:8px;
    right:8px;
    width:36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#333;
    cursor: pointer;
    z-index: 2;
}

.live-map-container .l-m-map-box{
    left:0;
    width:100%;
    z-index:1;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    
}

.live-map-container .l-m-map-box .l-map-container{
    width:100%;
    height:100%;
}

.live-map-container.open .l-m-map-box{
    left:300px;
    width:calc(100% - 300px)
}

.live-map-container.open .l-m-left-panel{
    left:0;
}

.live-map-container .l-m-zones{
    margin-top: 0;
    color:#333;
    padding:16px 16px 8px;
}

.live-map-container .l-m-zones .l-m-zones-select option{
    color:#555;
}

@media screen and (max-width:600px) {
    /* .live-map-container .l-m-left-panel{
        background-color: rgba(10,17,27,0.8);
    }*/
    .live-map-container.open .l-m-map-box{
        left:0;
        width:100%;
    } 
}