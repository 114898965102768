.bulkFileUploader {
    visibility: hidden;
    position: relative;
    height: 240px;
    width: 100%;
    display: block;
}

.bulkFileUploader .uploader {
    visibility: visible;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px dashed #ccc;
    border-radius: 10px;
    cursor: pointer;
}

.bulkFileUploaderContainer .choosenFile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    padding: 10px 16px;
    margin: 2px 0;
    border: 2px solid #E6E6E6;
}

.bulkFileUploaderContainer .error {
    background-color: rgba(255,0,0,0.3);
    padding: 6px;
    border-radius: 4px;
    margin: 5px 0;
    font-size: 0.875rem;
}

.bulkFileUploaderContainer .exceptions_container {
    display: flex;
    flex-direction: column;
    padding: 9px 15px;
    background-color: rgba(248, 209, 210, 0.4);
    max-height: 300px;
    overflow-y: auto;
    margin-top: 15px;
    border-radius: 12px;
    /* border: 1px dashed #ccc; */
}

.bulkFileUploaderContainer .exceptions_container .exp_item {
    padding: 4px 0;
}

.bulkFileUploaderContainer .exceptions_container .exp_item:not(:last-child) {
    /* border-bottom: 1px dashed #bbb; */
    margin-bottom: 6px;
}

.bulkFileUploaderSuccessTemp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bulkFileUploaderSuccessTemp .success_icon {
    border-radius: 50%;
    padding: 5px;
    margin: 10px auto 20px;
    border: 3px solid #1CC089;
}

.bulkFileUploaderContainer .success_state {
    background-color: rgba(0,128,0,0.3);
    padding: 12px 12px;
    margin-bottom: 6px;
    font-size: 1rem;
    border-radius: 2px;
}

.bulkFileUploaderProgress {
    width: 100%;
    position: relative;
    height: 10px;
    background-color: #ccc;
    border-radius: 4px;
    overflow: hidden;
}

.inner_bulkFileUploaderProgress {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
    background-color: green;
}