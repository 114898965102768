.statistics_container {
  grid-area: 1 / 1 / 2 / 6;
  display: flex;
  gap: 16px;
  /* margin-right: 16px; */
}

.statistics_container>div {
  flex: 1;
  border-radius: 8px;
}

.statistics_container .statistics-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 8px;
  height: 95px;
  font-size: 18px;
  background-color: #fff;
  box-shadow: 0 2px 3px #ccc;
}

.statistics_container .clickable {
  cursor: pointer;
}

.statistics_container .statistics-item .icon-container {
    width:40px;
    height:40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.statistics_container .statistics-item .icon-container.totalRiders {
  background-color: #B3B3B3;
  color: #666666;
}

.statistics_container .statistics-item .icon-container.activeRiders {
  background-color: #aafad2;
  color: #0EBC93;
}

.statistics_container .statistics-item .icon-container.groups {
  background-color: #fcdcac;
  color: #F39905;
}

.statistics_container .statistics-item .icon-container.zones {
  background-color: #ceccf8;
  color: #5251FA;
}

.statistics_container .statistics-item .icon-container.vehicles {
  background-color: #fdc8bc;
  color: #F33B12;
}

.statistics_container .statistics-item .value {
  font-size: 1.7em;
  color: #000;
  font-weight: 500;
  margin-top: 2px;
}

.statistics_container .statistics-item .title {
  font-size: 0.85em;
  color: #777;
  white-space: nowrap;
}

.statistics_container .statistics-item .desc {
  font-size: 0.60em;
  color: rgba(0,0,0,0.5);
  white-space: nowrap;
  margin-bottom: 2px;
}


@media screen and (max-width: 1100px) {
  .statistics_container {
    flex-wrap: wrap;
  }

  .statistics_container>div {
    flex: 1 1 48%;
  }
}