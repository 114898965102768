.l-m-zone-marker{
    position: absolute;
    width:56px;
    height: 56px;
    top:-28px;
    left:-28px;
    transition: all ease 0.2s;
    border-radius: 50%;
    background-color: #333;
    box-shadow: 0 0 10px 1px rgba(0,0,0,0.5);
    color:#fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
