.general-layout{
    position: relative;
    width:100%;
    min-height: 100vh;
}

.general-layout .left-box,.general-layout .right-box{
    position: absolute;
    top:0;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.general-layout .left-box{
    width:40%;
    left:0;
    padding:80px;
    border-right: 1px solid #eee;
}

.general-layout .right-box{
    right:0;
    width:60%;
    background-color: #F39905;
    background-size: cover;
}

.general-layout .right-box .mask,.general-layout .right-box .box-content{
    position: absolute;
    width:100%;
    height: 100%;
    top:0;
    left:0;
}

.general-layout .right-box .mask{
    background-color: rgba(0,0,0,0.7);
    z-index: 1;
}

.general-layout .right-box .box-content{
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.general-layout .auth-form-box{
    width:600px;
    max-width: 100%;
}


.logo-box svg{
    max-width:100%;
}


@media screen and (max-width:600px) {
    .general-layout{
        display: flex;
        flex-direction: column-reverse;
        min-height: auto;
    }

    .general-layout .left-box{
        position: static;
        width:100%;
        padding:24px;
    }
    .general-layout .right-box{
        position: static;
        width:100%;
        padding:0;
        background: none !important;
    }

    .general-layout .right-box .mask{
        display: none;
    }

    .general-layout .right-box .box-content{
        position: static;
        
    }

    .general-layout .right-box svg{
        margin:24px auto;
        height: 160px;
        width:160px;
        max-width:100%;

    }
}