.order_main_wrapper {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 120px);
}

.order_table_wrapper {
    flex: 1;
    border-radius: 10px;
    background-color: #fff;
    overflow-y: auto;
    overflow-x: auto;
}

.fixed_tab {
    position: sticky;
    width: 100%;
    top: 0;
    background-color: #fff;
    min-height: 60px;
    z-index: 2;
}