.vehicleAssignBody .vehicleInfoViewerBox {
    padding: 10px 10px;
    border-radius: 6px;
    margin: 0 0 6px;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.vehicleAssignBody .vehiclesDropDownContainer {
    border: 1px solid #ddd;
    padding: 6px;
    border-radius: 6px;
}

.vehicleAssignBody .vehiclesDropDownContainer .vehicleItemsBox {
    max-height: 200px;
    overflow-y: auto;
}

.vehicleAssignBody .vehiclesDropDownContainer .vehicleItem {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2px 0;
    font-size: 0.875rem;
    cursor: pointer;
}

.vehicleAssignBody .vehiclesDropDownContainer .vehicleItem.selected {
    background-color: rgb(17, 117, 248);
    color: #fff;
}

.vehicleAssignBody .vehiclesDropDownContainer .vehicleItem:not(:last-child) {
    border-bottom: 1px solid #eee;
}

.vehicleAssignBody .vehiclesDropDownContainer .vehicleItem:not(.selected):hover {
    background-color: #eee;
}
