.timeline_item_container {
    display: flex;
    width: 100%;
    margin: 10px;
}

.timeline_item_container.inside {
    justify-content: flex-start;
}

.timeline_item_container.outside {
    justify-content: flex-end;
}

.timeline_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 370px;
}

.timeline_item .details {
    min-width: 260px;
    height: 120px;
    border: 1px solid #E6E6E6;
    border-radius: 12px;
    background-color: rgba(230, 230, 230, 0.1);
    position: relative;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 1em;
    /* overflow: hidden; */
}

.timeline_item .details .inner_details {
    padding: 6px 12px;
}

.timeline_item .details .inner_details>div {
    margin-bottom: 5px;
}

.timeline_item .details .status {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.timeline_item .time {
    width: 70px;
    height: 35px;
    padding: 6px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 16px;
    z-index: 3;
    font-weight: 500;
}

.timeline_item .time_inmobile {
    font-weight: 500;
    min-height: 30px;
    display: none;
}

.timeline_item.green .time,
.timeline_item.green .time_inmobile {
    background-color: #E0EDCF;
    color: #65A30D;
}

.timeline_item.yellow .time,
.timeline_item.yellow .time_inmobile {
    background-color: #FEEFD6;
    color: #FBAE31;
}

.timeline_item.blue .time,
.timeline_item.blue .time_inmobile {
    background-color: #CCE8FD;
    color: #008AF6;
}

.timeline_item.red .time,
.timeline_item.red .time_inmobile {
    background-color: #FDD8D0;
    color: #F33B12;
}

.timeline_item.gray .time,
.timeline_item.gray .time_inmobile {
    background-color: rgba(119, 119, 119, 0.2);
    color: rgba(119, 119, 119, 1);
}

.timeline_item.lightgreen .time,
.timeline_item.lightgreen .time_inmobile {
    background-color: rgba(0, 212, 0, 0.2);
    color: rgba(0, 212, 0, 1);
}

.timeline_item.crimson .time,
.timeline_item.crimson .time_inmobile {
    background-color: rgba(159, 29, 0, 0.2);
    color: rgba(159, 29, 0, 1);
}

/* ******************** */
.timeline_item .log_type {
    font-weight: 500;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
}

.timeline_item.green .log_type {
    color: #65A30D;
}

.timeline_item.yellow .log_type {
    color: #FBAE31;
}

.timeline_item.blue .log_type {
    color: #008AF6;
}

.timeline_item.red .log_type {
    color: #F33B12;
}

/* ******************** */

.timeline_item_container.inside .time {
    text-align: right;
}

.timeline_item_container.outside .time {
    text-align: left;
}

.timeline_item_container .time:before,
.timeline_item_container .time:after {
    content: "";
    position: absolute;
    width: 1px;
    height: 53px;
    background-color: #E6E6E6;
    z-index: -1;
}

.timeline_item_container .time:before {
    bottom: 35px;
}

.timeline_item_container .time:after {
    top: 35px;
}

.timeline_item_container:first-child .time:before {
    display: none;
}

.timeline_item_container:last-child .time:after {
    display: none;
}

.timeline_item_container.inside .details:after,
.timeline_item_container.outside .details:before {
    content: "";
    position: absolute;
    width: 40px;
    height: 1px;
    background-color: #E6E6E6;
    z-index: 1;
    top: 50%;
}

.timeline_item_container.inside .details:after {
    right: -40px;
}

.timeline_item_container.outside .details:before {
    left: -40px;
}


@media screen and (max-width: 420px) {
    .timeline_item_container {
        margin: 0 auto;
    }

    .timeline_item_container .time {
        display: none;
    }

    .timeline_item_container .time:before,
    .timeline_item_container .time:after {
        display: none;
    }

    .timeline_item_container.inside .details:after,
    .timeline_item_container.outside .details:before,
    .timeline_item_container.inside .details:before,
    .timeline_item_container.outside .details:after {
        display: none;
    }

    .timeline_item {
        width: 100%;
        justify-content: center;
    }

    .timeline_item .details {
        width: 100%;
    }

    .timeline_item .time_inmobile {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        border-radius: 12px 12px 0 0;
    }
}