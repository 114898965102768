.chartInfoContainer {
    display: flex;
    justify-content: center;
    height: 220px;
}

.chartInfoContainer .chart {
    position: relative;
}

.chartInfoContainer .chart ,
.chartInfoContainer .info {
    flex: 1;
    width: 100%;
    object-fit: contain;
}

.chartInfoContainer .info {
    margin-top: 4px;
}

.chartInfoContainer .info .item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
    font-size: 0.875rem;
    margin-bottom: 3px;
}

.text_overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.chartInfoContainer .info .item .bullet {
    width: 15px;
    height: 15px;
    border-radius: 50%;
}