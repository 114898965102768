.map-statistics {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 50px;
    background-color: #fff;
    margin-bottom: 6px;
    flex-direction: row-reverse;
    box-shadow: 0 2px 5px #ccc;
    padding: 8px 16px 8px 0 ;
    font-size: 0.9rem;
    border-left: 1px solid #efefef;
}

.map-statistics .statistics-item {
    margin-left: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    position: relative;
}

.map-statistics .statistics-item .title {
    font-size: 0.8em;
    color: #9e9d9d;
    margin-top: 8px;
    font-weight: 500;
}

.map-statistics .statistics-item .value {
    margin-right: 2px;
    margin-top: -2px;
    font-size: 1.15em;
    font-weight: 500;
    color: #333;
}

.map-statistics .statistics-item:after {
    content: "";
    width: 4px;
    height: 100%;
    border-radius: 100px;
    margin-right: 5px;
    position: absolute;
    left: -10px;
}

.map-statistics .statistics-item.type1:after {
    background-color: #008000;
}

.map-statistics .statistics-item.type2:after {
    background-color: #ff0000;
}

.map-statistics .statistics-item.type3:after {
    background-color: #ae00ff;
}

.map-statistics .statistics-item.type4:after {
    background-color: #0b6dff;
}

.map-statistics .statistics-item.type5:after {
    background-color: #333333;
}