@keyframes loading-animation{
    from{
        left:0;
    }
    to{
        left:100%;
    }
}

.horizontal-animate-item{
    display: block;
    width:100%;
    position: relative;
    height: 5px;
    overflow: hidden;
}

.horizontal-animate-item:after{
    content:"";
    position: absolute;
    left:0;
    top:0;
    width:20%;
    height: 100%;
    border-radius: 4px;
    background-color: #55aab1;
    animation: loading-animation 3s linear infinite;
}