
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.circle_skeleton {
    position: relative;
    width: 100%;
    height: 100%;
}

.circle_skeleton .loading {
    position: absolute;
    background-color: #e7e7e7;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .circle_skeleton .loading.hide {
    display: none;
  }
  
  .circle_skeleton .loading:after {
    content: "";
    border-radius: 50%;
    border: 7px solid #aaa;
    border-top-color: #ccc;
    animation: loading 2s linear infinite;
  }
  
  .circle_skeleton .loading.l-sm:after {
    width: 40px;
    height: 40px;
  }
  
  .circle_skeleton .loading.l-md:after {
    width: 70px;
    height: 70px;
  }
  
  .circle_skeleton .loading.l-lg:after {
    width: 90px;
    height: 90px;
  }