.general-content{
    padding:84px 24px;
    width:100%;
    max-width: 700px;
    margin:0 auto;
    user-select: none;
}

.general-content h2{
    color:#2d2d2d;
    font-size: 1rem;
    margin:40px 0 14px 0;
}

.general-content p{
    font-size: 0.75rem;
    color:#565655;
    margin:0;
    line-height: 19.2px;
    text-align: justify;
}

.general-content ul li {
    font-size: 0.8rem;
    color:#565655;
    margin-left: 25px;
}

.general-content hr.section {
    margin: 20px 0;
}