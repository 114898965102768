.searchable_list {
    position: relative;
}

.searchable_list button {
    font-weight: 400;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ddd;
    font-size: 1rem;
    position: relative;
}

.searchable_list .searchable_list_dropdown {
    width: 100%;
    overflow-y: hidden;
    padding: 6px;
    position: absolute;
    font-size: 0.875rem;
    background-color: #fff;
    border-radius: 6px;
    margin-top: 8px;
    display: none;
    z-index: 2;
    box-shadow: 0 1px 3px #ddd;
    border: 1px solid #eee;
}

.searchable_list .searchable_list_dropdown.open {
    display: block;
    box-shadow: 0 1px 2px #ddd;
}

.searchable_list .searchable_list_dropdown .options_container {
    max-height: 300px;
    overflow-y: auto;
}

.searchable_list .searchable_list_dropdown .searchable_list_option {
    padding: 2px 6px;
}

.searchable_list .searchable_list_dropdown .searchable_list_option:hover {
    background-color: #eee;
}