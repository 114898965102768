.order_update_container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    background-color: #fff;
    border-radius: 16px;
    padding: 25px;
    border: 1px solid #E6E6E6;
}

.order_update_container > div {
    flex: 1 1;
    width: 100%;
}

.form_inputs_container > div {
    margin-bottom: 25px;
}

@media screen and (max-width: 768px) {
    .order_update_container {
        flex-direction: column;
    }
}