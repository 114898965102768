.idle_riders {
    grid-area: 2 / 1 / 4 / 6;
    font-size: 18px;
    margin: 14px 0;
    height: 600px;
  }
  
  .idle_riders .refresh_btn {
    border: 1px solid #F39905;
    color: #F39905;
    padding: 4px 12px;
    background-color: transparent;
  }
  
  .idle_riders .export_btn {
    color: #333;
    background-color: #F39905;
    padding: 4px 12px;
    margin-left: 8px;
  }
  
  .idle_parent_table {
    height: calc(100% - 40px);
  }
  
  .idle_parent_table th {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
  }
  
  .inactive_table_container {
    height: calc(100% - 75px);
    overflow-y: auto;
  }
  
  .idle_riders .value {
    font-size: 1.7em;
    color: #000;
    font-weight: 500;
    margin-top: -6px;
  }
  
  .idle_riders .title {
    font-size: 0.85em;
    color: #777;
  }