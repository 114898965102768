.avatar_preview {
    width: 150px;
    height: 150px;
    background-color: #dddddd;
    position: relative;
    border-radius: 50%;
}

.avatar_preview #avatarImage {
    object-fit: cover;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    width: 100%;
    height: auto;
}

.avatar_preview #avatarFileInput {
    visibility: hidden;
}

.avatar_preview #avatarFileInput #cameraIcon {
    border-radius: 50%;
    visibility: visible;
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    right: 8px;
    z-index: 5;
    background-color: #ffffff;
}