.content-view{  
    position:relative;
}

.content-view .view-header{
    margin-bottom:24px;
}

.content-view .view-body{
    background-color:white;
    border-radius:12px; 
    width:760px;
    max-width:100%;
    padding:24px;
    box-shadow: 0 0 5px 1px rgba(0,0,0,0.1);
    border:1px solid #dcdcdc;
}