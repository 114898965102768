.flex_filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.filters_container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 6px;
}

.filters_container label {
    margin-bottom: 2px;
}

.filters_container > * {
    margin-bottom: 8px;
    width: auto !important;
}

.filters_container .reactDatePicker_container {
    width: min-content !important;
}

.filters_container .reactDatePicker_container label {
    font-weight: 500;
}

.filters_container .reactDatePicker {
    height: 2.5rem;
    padding: 0 10px;
    border-radius: 6px;
    border: 1px solid #ddd;
}

.sum_hours {
    display: flex;
    align-items: center;
}

#search_btn {
    margin-left: 8px;
}

@media screen and (max-width:600px) {
    .filters_container , .flex_filters {
        display: flex;
        flex-direction: column;
    }

    .filters_container {
        width: 100%;
        gap: 4px;
    }

    .filters_container > * {
        width: 100% !important;
        margin: 0 !important;
    }

    .filters_container input ,
    .filters_container button {
        width: 100% !important;
    }

    .filters_container .reactDatePicker_container {
        width: 100% !important;
    }

    #search_btn {
        margin: 4px 0 8px 0;
    }
}