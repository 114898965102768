.live-map-container .l-m-riders {
    color: #333;
    overflow-y: hidden;
    height: 100%;
    border-top: 1px solid #eee;
    padding-top: 8px;
}

.live-map-container .l-m-riders input {
    width: 90%;
    display: block;
    margin: 0 auto 6px;
}

.l-m-riders .l-m-rider-item {
    display: flex;
    flex-direction: column;
    padding: 6px 10px;
    cursor: pointer;
    transition: all ease 0.2s;
    user-select: none;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    margin: 8px;
    border: 3px solid #e6e6e6;
}

.l-m-riders .l-m-rider-item:hover {
    background-color: #f5f5f5;
}

.l-m-riders .l-m-rider-item.active {
    border: 3px solid #fbae31;
}

.l-m-rider-item-header {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 1rem;
    width: 100%;
    padding-bottom: 4px;
    margin-bottom: 3px;
    border-bottom: 1px solid #E6E6E6;
}

.l-m-rider-item-header .l-m-rider-item-name {
    text-overflow: ellipsis;
    word-break: break-all;
    overflow: hidden;
    font-weight: 500;
    font-size: 0.88rem;
}

.l-m-rider-item-status-bullet,
.l-m-rider-item-zone {
    position: relative;
    display: flex;
    align-items: center;
}

.l-m-rider-item-status-bullet:before,
.l-m-rider-item-zone:before {
    content: "";
    display: inline-block;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #e6e6e6;
}

.l-m-rider-item-zone:after {
    content: "";
    position: absolute;
    width: 4px;
    height: 15px;
    border-right: 2px dotted #E6E6E6;
    top: -14px;
    left: 13px;
    z-index: 0;
}

.l-m-rider-item-zone .icon {
    position: absolute;
    left: 6px;
    top: 4px;
    color: #5251FA;
}

.l-m-rider-item-status {
    display: flex;
    align-items: center;
    min-height: 35px;
}

.l-m-rider-item-status-bullet:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translate(7px, -7px);
    width: 16px;
    height: 16px;
    border-radius: 50%;
}

.l-m-rider-item-status-bullet.item-none:after {
    background-color: gray;
}

.l-m-rider-item-status-bullet.item-check-in-offline:after {
    background-color: orange;
}

.l-m-rider-item-status-bullet.item-check-in-online:after {
    background-color: green;
}

.l-m-rider-item-status-bullet.item-check-out:after {
    background-color: red;
}

.l-m-rider-item-status-bullet.item-available:after {
    background-color: rgba(0, 212, 0, 1);
}

.l-m-rider-item-status-bullet.item-unavailable:after {
    background-color: rgb(179, 179, 179);
}

.l-m-rider-item-status-bullet.item-busy:after {
    background-color: rgba(159, 29, 0, 1);
}

.l-m-riders .empty-list {
    display: flex;
    padding: 16px;
    align-items: center;
    justify-content: center;
}

.l-m-riders .l-m-riders-list {
    height: calc(100% - 144px);
    overflow-y: auto;
}