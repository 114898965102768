.filter_field {
    background-color: #fff;
    border-radius: 6px;
    font-weight: 400;
    border: 1px solid #e6e6e6;
    font-size: 0.95rem;
}

.filter_drop_down {
    width: 300px;
    background-color: #fff;
    padding: 8px;
    border-radius: 8px;
    z-index: 5;
    box-shadow: 0 1px 5px #cecece;
}

.filter_drop_down .items_container {
    max-height: 200px;
    overflow-y: auto;
}