.zones_container {
    max-height: 300px;
    overflow-y: auto;
    padding: 8px 6px;
    border-radius: 8px;
    border: 1px solid #e6e6e6;
    position: relative;
    margin-bottom: 10px;
}

.zones_container .zone_item {
    padding: 6px 8px;
    cursor: pointer;
    display: block;
    font-size: 0.875rem;
    border-radius: 6px;
}

.zones_container .zone_item:hover {
    background-color: #efefef;

}

.closeModal {
    position: absolute;
    top: 16px;
    right: 15px;
}