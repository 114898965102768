.timeline_container_box {
    padding: 25px 0;
    background-color: #fff;
    border-radius: 16px;
    margin-bottom: 16px;
    border: 1px solid #E6E6E6;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    height: 100%;
}

.timeline_container {
    width: 670px;
}

.attendance_timeline_container {
    max-width: 1200px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.timeline_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.timeline_filters {
    display: flex;
    align-items: center;
}

.timeline_filters .searchable_list_filter {
    margin-right: 4px;
}

.timeline_filters .searchable_list_filter,
.timeline_filters .date_filter {
    width: 100%;
}

.total_time_box {
    padding-left: 25px;
    margin: 16px 0;
    border: 1px solid #E6E6E6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 16px;
    background-color: #fff;
    min-height: 100px;
    font-size: 0.9rem;
}

.total_time_box .total_time_item {
    display: flex;
    align-items: center;
    height: 60%;
}

.total_time_box .total_time_item:last-child {
    padding-left: 25px;
    border-left: 1px solid #E6E6E6;
}

.total_time_box > div {
    flex: 1;
}

.total_time_mobile {
    display: none;
}

.total_time_desk {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 420px) {
    .timeline_container {
        width: 90%;
    }

    .timeline_header {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .timeline_filters {
        width: 100%;
        flex-direction: column;
    }

    .timeline_filters input {
        margin-top: 6px;
        width: 100%;
    }

    .timeline_filters .searchable_list_filter {
        margin-right: 0;
    }

    .total_time_mobile {
        display: flex;
        flex-direction: column;
    }

    .total_time_desk {
        display: none;
    }
}