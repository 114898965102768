.manage-zone-riders .m-z-r-header{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 56px;
}

.manage-zone-riders .m-z-r-header > div{
    font-size:1.2rem;
    font-weight: 500;
}

.manage-zone-riders .m-z-r-header > span{
    color:#999;
    position: absolute;
    top:50%;
    transform:translateY(-50%);
    width:24px;
    height: 24px;
    cursor:pointer;
}

.manage-zone-riders .m-z-r-header > span.back-btn{
    left: 24px;
}

.manage-zone-riders .m-z-r-header > span.close-btn{
    right:24px;
}

.riders-container {
    height: 300px;
    overflow-y: auto;
    border-radius: 8px;
    border : 1px solid #E6E6E6;
    padding-top: 2px;
    padding-bottom: 2px;
}