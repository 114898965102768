.bullet_type_wrapper {
    position: relative;
    border-radius: 50%;
}

.bullet_type_wrapper.red {
    background-color: #FDD8D0;
}

.bullet_type_wrapper.red .bullet_type {
    background-color: #F33B12;
}

.bullet_type_wrapper.blue {
    background-color: #CCE8FD;
}

.bullet_type_wrapper.blue .bullet_type {
    background-color: #008AF6;
}

.bullet_type_wrapper.green {
    background-color: #E0EDCF;
}

.bullet_type_wrapper.green .bullet_type {
    background-color: #65A30D;
}

.bullet_type_wrapper.yellow {
    background-color: #FEEFD6;
}

.bullet_type_wrapper.yellow .bullet_type {
    background-color: #FBAE31;
}

.bullet_type_wrapper.gray {
    background-color: rgba(119, 119, 119, 0.2);
}

.bullet_type_wrapper.gray .bullet_type {
    background-color: rgba(119, 119, 119, 1);
}

.bullet_type_wrapper.lightgreen {
    background-color: rgba(0, 212, 0, 0.2);
}

.bullet_type_wrapper.lightgreen .bullet_type {
    background-color: rgba(0, 212, 0, 1);
}

.bullet_type_wrapper.crimson {
    background-color: rgba(159, 29, 0, 0.2);
}

.bullet_type_wrapper.crimson .bullet_type {
    background-color: rgba(159, 29, 0, 1);
}

.bullet_type {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}