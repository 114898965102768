.l-m-rider-marker {
    width: 56px;
    height: 56px;
    position: absolute;
    top: -30px;
    left: -15px;
    border-radius: 50%;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
}

.l-m-rider-marker>span {
    width: 56px;
    height: 56px;
    color: #333
}

.l-m-rider-marker:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 3px solid #fff;
    z-index: 3;
}

.l-m-rider-marker.item-none:after {
    background-color: gray;
}

.l-m-rider-marker.item-check-in-offline:after {
    background-color: orange;
}

.l-m-rider-marker.item-check-in-online:after {
    background-color: green;
}

.l-m-rider-marker.item-check-out:after {
    background-color: red;
}

.l-m-rider-marker.item-available:after {
    background-color: rgba(0, 212, 0, 1);
}

.l-m-rider-marker.item-unavailable:after {
    background-color: rgb(179, 179, 179);
}

.l-m-rider-marker.item-busy:after {
    background-color: rgba(159, 29, 0, 1);
}


.l-m-rider-marker .l-m-rider-details {
    position: absolute;
    bottom: 58px;
    left: 50%;
    transform: translateX(-50%);
    width: 256px;
    height: auto;
    background-color: #fff;
    border-radius: 8px;
    z-index: 10;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.5);
}

.l-m-rider-marker .l-m-rider-details .l-m-marker-content {
    padding: 10px 20px 12px 8px;
    font-size: 1rem;
    color: #000;
    font-weight: normal;
}

.l-m-rider-marker .l-m-rider-details .l-m-marker-content .fullName,
.l-m-rider-marker .l-m-rider-details .l-m-marker-content .zoneName {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.l-m-rider-marker .l-m-rider-details .l-m-marker-content .status_bullet {
    position: relative;
    min-width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #e6e6e6;
}

.l-m-rider-marker .l-m-rider-details .l-m-marker-content .status_bullet:after {
    content: "";
    position: absolute;
    top: 6px;
    left: 7px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.l-m-rider-marker .l-m-rider-details .l-m-marker-content .status_bullet.item-none:after {
    background-color: gray;
}

.l-m-rider-marker .l-m-rider-details .l-m-marker-content .status_bullet.item-check-in-offline:after {
    background-color: orange;
}

.l-m-rider-marker .l-m-rider-details .l-m-marker-content .status_bullet.item-check-in-online:after {
    background-color: green;
}

.l-m-rider-marker .l-m-rider-details .l-m-marker-content .status_bullet.item-check-out:after {
    background-color: red;
}

.l-m-rider-marker .l-m-rider-details .l-m-marker-content .status_bullet.item-available:after {
    background-color: rgba(0, 212, 0, 1);
}

.l-m-rider-marker .l-m-rider-details .l-m-marker-content .status_bullet.item-unavailable:after {
    background-color: rgb(179, 179, 179);
}

.l-m-rider-marker .l-m-rider-details .l-m-marker-content .status_bullet.item-busy:after {
    background-color: rgba(159, 29, 0, 1);
}

.l-m-rider-marker .l-m-rider-details .l-m-marker-content>div:not(:last-child) {
    margin-bottom: 8px;
}

.l-m-rider-marker .l-m-rider-details:after,
.l-m-rider-marker .l-m-rider-details:before {
    /* content: ""; */
    left: 50%;
    transform: translateX(-50%);
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    position: absolute;
}

.l-m-rider-marker .l-m-rider-details:after {
    bottom: -7px;
    border-top: 7px solid white;
}

.l-m-rider-marker .l-m-rider-details:before {
    bottom: -8px;
    border-top: 7px solid #000;
}

.l-m-rider-marker .l-m-rider-details .close-btn {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

.RiderMarkerCanvas {
    border-radius: 50%;
    background-color: #fff;
    color: #000;
    border: 1px solid #aaa;
    padding: 2px;
    cursor: pointer;
}