.dashboard_container {
  width: 100%;
  margin-top: -18px;
}

.dashboard_container .box_area {
  box-shadow: 0 2px 3px #ccc;
  border-radius: 10px;
  background-color: #fff;
  padding: 15px;
  position: relative;
  overflow: hidden;
}

.dashboard_container .charts_container {
  display: flex;
  align-items: center;
  gap: 14px;
}



@media screen and (max-width: 1400px) {
  .charts_container {
      flex-direction: column;
  }
}