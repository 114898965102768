.dashboard-layout {
    position: relative;
    min-width: 100%;
    min-height: 100vh;
    background-color: #f6f6f6;
}


.dashboard-layout .navbar {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    border-bottom: 1px solid transparent;
}

.dashboard-layout .navbar:after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 50%;
    width: calc(100% - 210px);
    height: 1px;
    /* background-color: #dcdcdc; */
    transform: translateX(-50%)
}

.dashboard-layout .navbar .brand {
    display: flex;
    align-items: center;
}

.dashboard-layout .navbar .brand button {
    margin-right: 16px;
    background-color: transparent;
    box-shadow: none;
    transition: all ease 0.2s;
    width: 80px;
}

.dashboard-layout .navbar .brand button:active {
    background-color: #ffe2ec;
}


.dashboard-layout .content {
    width: 100%;
    padding: 24px;
    padding-left: 340px;
    overflow-y: auto;
    transition: all ease 0.2s;
    position: fixed;
    height: calc(100vh - 60px);
    top: 60px;
    left: 0;
}

.dashboard-layout .content.expand {
    padding-left: 120px;
}

/* ---------------------------------------------------DRAWER-------------------------------------------------------------------------- */

.dashboard-layout .drawer {
    position: fixed;
    z-index: 10;
    top: 60px;
    left: 16px;
    width: 80px;
    height: calc(100% - 76px);
    transition: all ease 0.2s;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
    background-color: #333;
    border-radius: 8px;
    padding: 16px 0;
    white-space: nowrap;
    overflow-x: hidden;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.dashboard-layout .d-back-drop {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
}

.dashboard-layout .drawer .d-close-btn {
    user-select: none;
    position: absolute;
    top: 16px;
    right: 16px;
    display: none;
    color: #fff;
}

.dashboard-layout .drawer:hover {
    width: 300px;
    /* transition-delay: 0.2s; */
}

.dashboard-layout .drawer.expand {
    width: 300px;
}

.dashboard-layout .drawer .item-text {
    visibility: hidden;
    width: 0;
    overflow: hidden;
}


.dashboard-layout .drawer.expand .item-text,
.dashboard-layout .drawer:hover .item-text {
    visibility: visible;
    width: auto;
}

.dashboard-layout .drawer:not(.expand) .ex-item-target {
    /* padding:0; */
    width: 100%;
    display: flex;
    justify-content: center;
}

.dashboard-layout .drawer:not(.expand) .ex-item-target>svg.ex-target-icon {
    margin-right: 0;
}

.dashboard-layout .drawer:not(.expand):hover .ex-item-target {
    justify-content: initial;
}

.dashboard-layout .drawer:not(.expand):hover .ex-item-target>svg.ex-target-icon {
    margin-right: 8px;
}

.dashboard-layout .drawer .d-category {
    margin-bottom: 16px;
    padding: 0 8px;
}

.drawer .d-category .d-c-title {
    font-weight: 500;
    padding: 8px 0;
    border-bottom: 1px solid #ccc;
    color: #ccc;
    user-select: none;
    display: flex;
    align-items: center;

}

.drawer .d-category .d-c-title>svg {
    margin-right: 8px;
}

.drawer .d-category .d-c-title>span {
    margin-top: 4px;
}

.drawer .d-category .d-c-menu-items {
    margin-top: 8px;
}

.drawer .d-category .d-c-menu-items>li .nav-link {
    display: flex;
    padding: 12px 8px;
    align-items: center;
    color: #fff;
    /* border-left:3px solid transparent; */
}

.drawer .d-category .d-c-menu-items>li .nav-link>svg {
    margin-right: 8px;
}

.drawer .d-category .d-c-menu-items>li .nav-link.active {
    background-color: #F39905;
    /* border-color:#F39905; */
    color: #000;
    position: relative;
    border-radius: 12px;
}

/* .drawer .d-category .d-c-menu-items > li .nav-link.active svg{
    color:#F39905;
} */

.nav-logo {

    width: 80px;
}

.cropper-dashed.dashed-v:after {
    content: "";
    border-radius: 50%;
    height: 100%;
    aspect-ratio: 1/1;
    top: 50%;
    left: 50%;
    background-color: rgba(0, 0, 0, 0.8);
    transform: translate(-50%, -50%);
    position: absolute;
    border: 1px dashed rgba(255, 255, 255, 0.8);
    z-index: 0;
}

.table_container {
    width: 100%;
    overflow-x: auto;
}

.rider-table-message {
    min-width: 400px;
}

button:focus {
    outline: none !important;
    box-shadow: none !important;
}


@media screen and (max-width:600px) {

    .dashboard-layout .navbar .brand button {
        width: auto;
    }

    .dashboard-layout .drawer {
        position: absolute;
        top: 0;
        left: -300px;
        z-index: 11;
        height: 100%;
        box-shadow: none;
    }

    .dashboard-layout .drawer.expand {
        left: 0;
    }

    .dashboard-layout .drawer .d-close-btn {
        display: block;
    }

    .dashboard-layout .content,
    .dashboard-layout .content.expand {
        padding-left: 8px;
        padding-right: 8px;
    }

    .dashboard-layout .d-back-drop.show {
        display: block;
    }
}
