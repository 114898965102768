.image_container {
    position: relative;
    border-radius: 16px;
    height: 800px;
    /* max-height: 1000px; */
    overflow: hidden;
    background-color: #E6E6E6;
}

.image_container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 0.3s ease-out;
    image-rendering: crisp-edges;
    outline: 1px solid #000;
    filter: contrast(1.2);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.image_container img:hover {
    cursor: zoom-in;
}
  