.notification_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #555;
    background: transparent;
}

.notification_badge_container {
    width: 22px;
    height: 22px;
    position: absolute;
    top: -7px;
    right: -5px;
}

.notification_badge {
    
    background-color: #EB5757;
    border-radius: 50%;
    color: #fff;
    font-size: 10px;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.notification_dialogue {
    background-color: #fff;
    outline: 2px solid #E6E6E6;
    max-height: 408px;
    width: 482px;
    overflow-y: hidden;
    border-radius: 12px;
    display: none;
    position: absolute;
    margin-top: 6px;
    right: -35px;
    z-index: 8;
    font-size: 1em;
}

.nd_padder {
    padding-right: 20px;
    padding-left: 20px;
}

.notification_dialogue.show {
    display: flex;
    flex-direction: column;
}

.notification_dialogue_header {
    min-height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
}

.notification_items_container {
    overflow-y: auto;
}

.notification_items_container .spliter_line {
    height: 1px;
    border-bottom: 1px solid #e6e6e6;
    width: 100%;
}

.notification_items_container .notification_item {
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    font-size: 1em;
}

.notification_items_container .notification_item.seen {
    background-color: #e0e0e0;
    opacity: 0.35;
}

.notification_items_container .notification_item .created_time {
    margin-top: 5px;
    color: #ccc;
}

.notification_items_container .notification_item.seen .created_time{
    color: #868686;
}
